"use client";

import { ProductData } from "@/lib/5874/types";
import { getSearchSpringProductData } from "@/lib/searchspring/searchspringAPI";
import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

export type IntelData = {
	sku: string;
	name: string;
	data: string;
	signature: string;
};

interface ProductContextProps {
	product: ProductData;
	intellisuggest: IntelData[];
	selectedVariant: any;
	setSelectedVariant: (variant: any) => void;
	findProductIntelData: (product: ProductData) => IntelData;
	skuList: string[];
	setSkuList: Dispatch<SetStateAction<string[]>>;
}

export const ProductContext = createContext({} as ProductContextProps);

interface ProviderProps {
	product: ProductData;
	children?: React.ReactNode;
}

export const ProductContextProvider = ({
	product,
	children,
}: ProviderProps) => {
	const initialIntelState = [{ sku: "", name: "", data: "", signature: "" }];
	const [skuList, setSkuList] = useState<string[]>([]);
	const [selectedVariant, setSelectedVariant] = useState();
	const [intellisuggest, setIntellisuggest] =
		useState<IntelData[]>(initialIntelState);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const results = await getSearchSpringProductData();
				setIntellisuggest(results);
			} catch (err) {
				console.error(err);
			}
		};

		fetchData();
	}, []);

	// Curried findProductIntelData with intellisuggest
	const findProductIntelData = (product: ProductData): IntelData => {
		const emptyObj = { sku: "", name: "", data: "", signature: "" };
		if (!intellisuggest) {
			return emptyObj;
		}

		const productIntel = intellisuggest.find((p) => p.sku === product.sku);

		if (!productIntel) {
			return emptyObj;
		}

		return productIntel;
	};

	const value = {
		product,
		intellisuggest,
		selectedVariant,
		setSelectedVariant,
		findProductIntelData,
		skuList,
		setSkuList,
	};

	return (
		<ProductContext.Provider value={value}>{children}</ProductContext.Provider>
	);
};

export const useProductContext = () => {
	const context = useContext(ProductContext);

	if (!context) {
		throw new Error(
			"useProductContext must be used within a ProductContextProvider",
		);
	}

	return context;
};

export const getIntellisuggestInfo = (
	intellisuggest: IntelData[] | undefined,
	name: string,
) => {
	if (!intellisuggest) return { name: "", data: "", signature: "" };

	const intellisuggestName = intellisuggest.filter(
		(intel: IntelData) => intel.name === name,
	);

	const intellisuggestData = intellisuggestName
		.map((intel: IntelData) => intel.data)
		.toString();
	const intellisuggestSignature = intellisuggestName
		.map((intel: IntelData) => intel.signature)
		.toString();

	return {
		names: intellisuggestName,
		data: intellisuggestData,
		signature: intellisuggestSignature,
	};
};

export const findProductIntelData = (
	intelData: IntelData[] | undefined,
	product: ProductData,
) => {
	if (!intelData) {
		return { name: "", data: "", signature: "" };
	}

	const productIntel = intelData.find((p) => p.name === product.name);
	return productIntel;
};
