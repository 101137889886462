"use client";

import React from "react";
import styles from "./ServiceCenters.module.scss";
import { Builder } from "@builder.io/react";
import { initBuilder } from "@/lib/builder/builder-utils";
import { builder } from "@builder.io/sdk";
import Markdown from "../UI/Markdown/Markdown";
import Button from "../UI/Button/Button";
import * as Select from "@radix-ui/react-select";
import clsx from "clsx";
import { useScopedI18n } from "@/i18n/client";

import Chevron from "@/app/assets/images/svg/chevron.svg";

import { useState, useEffect } from "react";
import useIsPreviewingBuilder from "@/hooks/useIsPreviewingBuilder";

interface ServiceCentersProps {
	serviceCenter: [
		{
			selectServiceCenter: {
				value: {
					data: {
						serviceCentre: string;
						contactDetails: ContactDetails[];
						servingAreas: string;
						serviceCentreUrl: string;
					};
				};
			};
		},
	];
}
type ServiceCentreType = { serviceCentre: string };
interface RepresentativesProps {
	name: string;
	title: string;
	location?: string;
	email: string;
	phoneNumber?: string;
	image: string;
	imageAltText: string;
	serviceCenters: string[];
}

type ContactDetails = {
	address: string;
	phoneNumber: string;
	email: string;
};
interface SelectedServiceCenterProps {
	serviceCenter: string;
	contactDetails: ContactDetails[];
	servingAreas: string;
	serviceCenterUrl: string;
}

const ServiceCenters: React.FC<ServiceCentersProps> = ({ serviceCenter }) => {
	const [serviceCenterOption, setServiceCenterOption] = useState("All");
	const [representatives, setRepresentatives] = useState<
		RepresentativesProps[]
	>([]);

	const [serviceCentreDropDown, setServiceCenterDropDown] = useState<
		{ text: string; value: string }[]
	>([]);
	const [serviceCenterData, setServiceCenterData] = React.useState<
		SelectedServiceCenterProps[]
	>([]);

	useEffect(() => {
		getRepresentatives();
		getServiceCentres();

		return () => {
			setServiceCenterDropDown([]);
			setRepresentatives([]);
		};
	}, []);

	const getRepresentatives = async () => {
		await builder
			.getAll("representatives", { limit: 100, options: { noTargeting: true } })
			.then((reps) => {
				const repData: RepresentativesProps[] = reps.map(({ data }) => ({
					name: data?.name,
					title: data?.title,
					location: data?.location,
					email: data?.email,
					image: data?.image,
					imageAltText: data?.imageAltText,
					serviceCenters: data?.serviceCentres?.map((s: string[]) =>
						Object.values(s).flat().toString(),
					),
				}));

				setRepresentatives(repData);
			});
	};

	const getServiceCentres = async () => {
		await builder.getAll("service-centers", {}).then((serviceCenters) => {
			const scentre = serviceCenters.map((sc) => {
				return {
					text: sc?.data?.serviceCentre,
					value: sc?.data?.serviceCentre,
				};
			});
			const sorted = scentre.sort(function (a, b) {
				if (a.text < b.text) {
					return -1;
				}
				if (a.text > b.text) {
					return 1;
				}
				return 0;
			});
			sorted.unshift({ text: "All", value: "All" });
			setServiceCenterDropDown(sorted);
		});
	};

	const handleOnChange = (value: string) => {
		setServiceCenterOption(value);
	};

	const RenderRepresentatives = ({
		representatives,
		serviceCenter,
	}: {
		representatives: RepresentativesProps[];
		serviceCenter: string;
	}) => {
		const filteredReps = representatives.filter((rep) =>
			rep?.serviceCenters?.find((sc) => sc?.includes(serviceCenter)),
		);

		if (filteredReps.length < 1) {
			return (
				<h3 className={styles.noServiceCentres}>
					There are currently no representatives for this Service Center
				</h3>
			);
		}
		return (
			<div className={styles.representativesSection}>
				<h3>Representatives</h3>
				{filteredReps.map((rep) => (
					<div key={rep.email ?? rep.name} className={styles.representative}>
						<img src={`${rep.image}?format=webp`} alt={rep.imageAltText} />
						<div className={styles.representativeText}>
							<p className={styles.representativeName}>{rep.name}</p>
							{rep.title && (
								<p className={styles.representativeTitle}>{rep.title}</p>
							)}
							{rep.location && (
								<p className={styles.representativeTitle}>{rep.location}</p>
							)}
							<a href={`mailto:${rep.email}`}>
								<p>{rep.email}</p>
							</a>
							{/* commented out as per request on MOV-889, although may get readded in the future */}
							{/* <p className={styles.repsServiceCentreList}>
								{rep.serviceCenters.map((sc, i) => (
									<span key={i}>{sc}</span>
								))}
							</p> */}
						</div>
					</div>
				))}
				{serviceCenter &&
					serviceCenterData
						?.filter((sc) =>
							serviceCenterOption === "All"
								? true
								: sc?.serviceCenter === serviceCenterOption,
						)
						.map((sc) =>
							sc?.serviceCenterUrl ? (
								<Button
									key={sc.serviceCenterUrl}
									as={"link"}
									variant={"primary"}
									href={sc.serviceCenterUrl}
									className={`${styles.buttonFindOutMore} ${styles.marginTop}`}
								>
									Find Out More
								</Button>
							) : null,
						)}
			</div>
		);
	};

	const { publicKey } = initBuilder("global");
	const { isEditingBuilder } = useIsPreviewingBuilder();
	const [serviceCentreData, setServiceCentreData] = React.useState<any>(null);
	const t = useScopedI18n("text");

	useEffect(() => {
		if (!isEditingBuilder) {
			const massagedData = serviceCenter?.map((sc) => {
				const contactDetails =
					sc?.selectServiceCenter.value.data.contactDetails.map((s) => ({
						address: s.address,
						phoneNumber: s.phoneNumber,
						email: s.email,
					}));
				return {
					serviceCenter: sc?.selectServiceCenter?.value?.data.serviceCentre,
					contactDetails,
					servingAreas: sc?.selectServiceCenter?.value?.data.servingAreas,
					serviceCenterUrl:
						sc?.selectServiceCenter?.value?.data.serviceCentreUrl,
				};
			});
			setServiceCenterData(massagedData);
		}

		if (serviceCenter?.length) {
			builder
				.getAll("service-centers", {
					apiKey: publicKey,
					query: {
						id: {
							$in: serviceCenter?.map((sc: any) => sc.selectServiceCenter?.id),
						},
					},
				})
				.then((res) => {
					const sorted = res.sort((a, b) => {
						const indexA = serviceCenter.findIndex(
							(sc: any) => sc.selectServiceCenter?.id === a.id,
						);

						const indexB = serviceCenter.findIndex(
							(sc: any) => sc.selectServiceCenter?.id === b.id,
						);

						if (indexA < indexB) return -1;
						if (indexA > indexB) return 1;
						return 0;
					});

					const massagedData: SelectedServiceCenterProps[] = sorted.map(
						(sc) => {
							const contact = sc?.data?.contactDetails || [];
							const contactDetails = contact.map((d: any) => ({
								address: d.address,
								phoneNumber: d.phoneNumber,
								email: d.email,
							}));

							return {
								serviceCenter: sc?.data?.serviceCentre,
								contactDetails: contactDetails,
								servingAreas: sc?.data?.servingAreas,
								serviceCenterUrl: sc?.data?.serviceCentreUrl,
							};
						},
					);

					setServiceCenterData(massagedData);
				});
		}
		return () => setServiceCenterData([]);
	}, [isEditingBuilder, serviceCenter]);

	return (
		<section className={styles.serviceCenterSection}>
			<div className={styles.serviceCenterWrapper}>
				<div className={styles.serviceCenterTitle}>
					<h2>
						{serviceCenterOption == "All"
							? "Service Centers"
							: `${serviceCenterOption}`}
					</h2>
					<Select.Root
						onValueChange={handleOnChange}
						value={serviceCenterOption}
						defaultValue='All'
					>
						<Select.Trigger className={styles.trigger} aria-label={"Page size"}>
							<span>
								{"Region: "}
								<Select.Value placeholder={"select a region"} />
							</span>
							<Select.Icon className={styles.arrow}>
								<Chevron aria-labelledby='directional chevron' />
							</Select.Icon>
						</Select.Trigger>
						<Select.Portal>
							<Select.Content className={styles.content}>
								<Select.ScrollUpButton
									className={clsx([styles.scroll, styles.up])}
								>
									<Chevron aria-labelledby='directional chevron' />
								</Select.ScrollUpButton>
								<Select.Viewport className={styles.viewport}>
									{serviceCentreDropDown?.map((dropdown) => (
										<Select.Item
											key={dropdown.text}
											value={dropdown.value}
											className={styles.item}
										>
											<Select.ItemText>{dropdown.text}</Select.ItemText>
										</Select.Item>
									))}
								</Select.Viewport>
								<Select.ScrollDownButton
									className={clsx([styles.scroll, styles.down])}
								>
									<Chevron aria-labelledby='directional chevron' />
								</Select.ScrollDownButton>
							</Select.Content>
						</Select.Portal>
					</Select.Root>
				</div>
				<div className={styles.serviceCenters}>
					{serviceCenter &&
						serviceCenterData
							?.filter((sc) =>
								serviceCenterOption == "All"
									? sc
									: sc?.serviceCenter === serviceCenterOption,
							)
							.map((sc) => (
								<div
									key={sc?.serviceCenter}
									className={`${styles.serviceCenter} 													
									${serviceCenterOption !== "All" ? styles.withReps : ""}`}
									datatype={sc?.serviceCenter}
								>
									<p
										className={`${styles.servingAreas} ${
											serviceCenterOption == "All" ? styles.showContent : ""
										}`}
									>
										Serving: {sc?.servingAreas}
									</p>
									<div className={styles.scText}>
										<h3>{sc?.serviceCenter}</h3>

										<div key={sc?.contactDetails.length}>
											{sc?.contactDetails
												? sc.contactDetails.map((s) => (
														<div key={s.email}>
															<Markdown
																content={s?.address}
																className={styles.markdown}
															/>
															{s.phoneNumber && <p>{s.phoneNumber}</p>}
															{s.email && (
																<a
																	className={styles.scEmail}
																	href={`mailto:${s.email}`}
																>
																	<p>{s.email}</p>
																</a>
															)}
														</div>
												  ))
												: null}
										</div>

										{sc?.serviceCenterUrl && (
											<Button
												as={"link"}
												variant={"primary"}
												href={sc?.serviceCenterUrl}
												aria-label={`${t("findOutMore")} about ${
													sc?.serviceCenter
												}`}
												className={`${styles.buttonFindOutMore}
												${
													serviceCenterOption !== "All"
														? styles.showContent
														: ""
												}
											`}
											>
												{t("findOutMore")}
											</Button>
										)}
									</div>
								</div>
							))}
				</div>
				{representatives &&
					serviceCenterOption !== "All" &&
					RenderRepresentatives({
						representatives: representatives,
						serviceCenter: serviceCenterOption,
					})}
			</div>
		</section>
	);
};

export default ServiceCenters;

Builder.registerComponent(ServiceCenters, {
	name: "ServiceCenters",
	inputs: [
		{
			name: "serviceCenter",
			type: "list",
			subFields: [
				{
					name: "selectServiceCenter",
					type: "reference",
					model: "service-centers",
				},
			],
		},
	],
});

type ContactDetailsType = {
	address: string;
	phoneNumber: string;
	email: string;
};
